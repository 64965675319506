@use '../4-helpers' as *;

*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

html {
	height: 100%;
}

body {
	box-sizing: border-box;
	height: 100%;
}