@use '../4-helpers' as *;

.c-footer {
	display: flex;
	justify-content: flex-end;
	padding: 1.6rem 0;

	@include themed() {
		background-color: t('secondary-bg');
	}
}