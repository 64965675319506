@use "../4-helpers/" as *;

/*====================================================================*/
/*  Fonts
/*====================================================================*/

/*Inter ==============================================================*/

@font-face {
  font-family: "Inter-Regular";
  src: url("../../assets/fonts/inter/Inter-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter-Light";
  src: url("../../assets/fonts/inter/Inter-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter-ExtraLight";
  src: url("../../assets/fonts/inter/Inter-ExtraLight.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter-Thin";
  src: url("../../assets/fonts/inter/Inter-Thin.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter-SemiBold";
  src: url("../../assets/fonts/inter/Inter-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter-Bold";
  src: url("../../assets/fonts/inter/Inter-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter-ExtraBold";
  src: url("../../assets/fonts/inter/Inter-ExtraBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/*Nexa ==============================================================*/

@font-face {
  font-family: "NexaRegular";
  src: url("../../assets/fonts/nexa/NexaRegular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NexaLight";
  src: url("../../assets/fonts/nexa/NexaLight.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NexaThin";
  src: url("../../assets/fonts/nexa/NexaThin.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NexaBook";
  src: url("../../assets/fonts/nexa/NexaBook.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NexaBlack";
  src: url("../../assets/fonts/nexa/NexaBlack.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NexaBold";
  src: url("../../assets/fonts/nexa/NexaBold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NexaHeavy";
  src: url("../../assets/fonts/nexa/NexaHeavy.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NexaXBold";
  src: url("../../assets/fonts/nexa/NexaXBold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

/*====================================================================*/
/*  General
/*====================================================================*/

html {
  font-family: $font-primary-regular;
  font-size: 62.5%;

  @include respond(general-screen) {
    font-size: 56.25%;
  }

  @include respond(medium-screen) {
    font-size: 50%;
  }

  @include respond(large-screen) {
    font-size: 75%;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-primary-bold;
  @include themed() {
    color: t("primary-text");
  }
}

h1 {
  font-size: 3.2rem;
}

h2 {
  font-size: 2.8rem;
}

h3 {
  font-size: 2.4rem;
}

h4 {
  font-size: 2rem;
}

h5 {
  font-size: 1.8rem;
}

h6 {
  font-size: 1.6rem;
}

p {
  font-family: $font-primary-regular;
  font-size: 1.6rem;
  @include themed() {
    color: t("primary-text");
  }
}

ol,
li {
  font-family: $font-primary-regular;
  font-size: 1.6rem;
}

li:before {
  font-family: $font-primary-bold;
}

/*====================================================================*/
/*  Forms
/*====================================================================*/

.colored-label {
  font-family: $font-primary-regular;
  font-size: 1.6rem;
  color: $color-primary-base;
  font-weight: 700;
  line-height: 100%;
  padding-top: 2rem;
}

.label {
  font-family: $font-primary-regular;
  font-size: 1.6rem;
  color: #4120ce !important;
  font-weight: 700;
  line-height: 100%;
}

.checkbox-label {
  font-family: $font-primary-regular;
  font-size: 1.6rem;
}

.input {
  font-family: $font-primary-regular;
  font-size: 1.6rem;
}

.error-text,
.error-message {
  font-size: 1.2rem;
}

.time-error {
  margin-top: 2rem !important;
}

.error-border .MuiOutlinedInput-root{
  border: 1px solid red;
  outline: none;
}

.form-tab-btn,
.MuiStepLabel-label {
  font-size: 1.6rem;
  font-family: $font-primary-bold;
}

.select,
.select--error {
  font-family: $font-primary-regular;
  font-size: 1.6rem;
}

.radio-button-label {
  .MuiFormControlLabel-label {
    font-family: $font-primary-regular;
    font-size: 1.6rem;
  }
}

.multi-form-title {
  font-family: $font-primary-bold;
  font-size: 1.4rem;
}

.counter-text {
  font-family: $font-primary-regular;
  font-size: 1.2rem;
}

/*====================================================================*/
/*  Buttons
/*====================================================================*/

.btn-primary,
.btn-secondary,
.btn-tertiary,
.btn-link {
  font-family: $font-primary-bold;
}

.btn-primary,
.btn-secondary,
.btn-tertiary {
  &--large,
  &--large-full-width {
    font-size: 1.6rem;
  }

  &--medium,
  &--medium-full-width {
    font-size: 1.4rem;
  }
}

.btn-link {
  font-size: 1.6rem;
}

/*====================================================================*/
/*  Modal
/*====================================================================*/

.table-text {
  font-family: $font-primary-regular;
  font-size: 1.6rem;
}

/*====================================================================*/
/*  Sidebar
/*====================================================================*/

.sidebar-item {
  &__text {
    font-family: $font-primary-regular;
    font-size: 1.4rem;
  }
}

/*====================================================================*/
/*  Header
/*====================================================================*/

.page-title-text {
  line-height: 120%;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 3.2rem;
}

/*====================================================================*/
/* Menus
/*====================================================================*/

.menu-item {
  font-family: $font-primary-regular;
  font-size: 1.4rem;
}

/*====================================================================*/
/* Footer
/*====================================================================*/

.footer-text {
  font-size: 1.2rem;
}

/*====================================================================*/
/* Table
/*====================================================================*/

.c-main-content--table {
  .rdt_Table {
    font-family: $font-primary-regular;
    font-size: 1.4rem;
  }

  .rdt_TableRow {
    font-family: $font-primary-regular;
    font-size: 1.4rem;
  }

  .rdt_TableHeadRow {
    font-family: $font-primary-bold;
    font-size: 1.4rem;
  }
}

.edit-btn-text,
.delete-btn-text {
  font-size: 1.4rem;
  line-height: 1.4rem;
  white-space: nowrap;
}

/*====================================================================*/
/* User Profile
/*====================================================================*/

.profile-name {
  font-family: $font-primary-bold;
  font-size: 2.8rem;
}

.profile-item-category {
  font-family: $font-primary-bold;
  font-size: 1.6rem;
}

.profile-item-text {
  font-family: $font-primary-regular;
  font-size: 1.6rem;
}

.profile-section-title {
  font-family: $font-primary-bold;
  font-size: 2.4rem;
}

/*====================================================================*/
/* User Profile
/*====================================================================*/

.toast-message {
  font-family: $font-primary-regular;
  font-size: 1.4rem;
}

/*====================================================================*/
/* Map
/*====================================================================*/

.map-last-update {
  font-family: $font-primary-regular;
  font-size: 1.4rem;
}

.loading-map-text {
  font-size: 1.4rem;
}

/*====================================================================*/
/* Forecast
/*====================================================================*/

.map-controls-title {
  font-family: $font-primary-bold;
  font-size: 1.4rem;
}

.map-toggle-btn {
  font-family: $font-primary-regular;
  font-size: 1.4rem;
}

.scale-title {
  font-family: $font-primary-bold;
  font-size: 1.4rem;
}

/*====================================================================*/
/* Table Item Details
/*====================================================================*/

.details-label {
  font-family: $font-primary-bold;
  font-size: 1.6rem;
}

.header-container-map {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.7rem;
}


/*====================================================================*/
/* RainFall
/*====================================================================*/

.dialog-typography-title {
  font-family: $font-primary-semibold;
  font-size: 2.4rem;
}

.dialog-typography-subTitle{
  font-family: $font-primary-semibold;
  font-size: 1.4rem;
}

.dialog-content {
  max-width: 330px;
}
