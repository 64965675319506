@use '../4-helpers' as *;

.c-profile-section {
	width: 100%;
	border-radius: .4rem;
	@include themed() {
		background-color: t('tb-bg');
		border: .1rem solid t('primary-border');
	}
	box-shadow: none;

	&.Mui-expanded {
		margin: 0;
	}

	&:before {
		height: 0;
	}

	&:last-child {
		margin-top: 2.4rem;
	}
}

.profile-section-title {
	@include themed() {
		color: t('primary-text');
	}
}

.profile-summary {
	padding: 3.2rem;

	&.Mui-expanded {
		@include themed() {
			border-bottom: .1rem solid t('primary-border');
		}
	}
}

.c-profile-info {
	padding: 0 6rem;
}

.profile-summary-content {
	margin: 0;
	display: flex;
	align-items: center;
	min-height: 5rem;
	
	&.Mui-expanded {
		margin: 0;
	}
}

.c-profile-details {
	display: flex;
	padding: 3.5rem;

	&--primary {
		flex-direction: row;
		align-items: center;
	}

	&--secondary {
		flex-direction: column;
	}
}

.profile {
	&__item {
		display: flex;
		flex-direction: row;
		align-items: center;

		&:not(:last-child) {
			margin-bottom: 1.6rem;
		}
	}
}

.c-profile-avatar {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.profile-avatar {
	&__wrapper {
		height: 18rem;
		width: 18rem;
		@include themed() {
			background-color: t('icon-table-bg');
		}
	}
}

.profile-name {
	display: inline-flex;
	margin-bottom: 3.2rem;
	@include themed() {
		color: t('primary-text');
	}
}

.profile-item-category {
	display: inline-flex;
	margin-right: .8rem;
	@include themed() {
		color: t('primary-text');
	}
}

.profile-item-text {
	@include themed() {
		color: t('primary-text');
	}
}

.profile__icon-wrapper {
	height: 3.2rem;
	width: 3.2rem;
	margin-right: .8rem;
	@include themed() {
		background-color: t('icon-table-bg');
	}
}

.visually-hidden {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
}

.c-profile-img-controls {
	display: flex;
	flex-direction: row;
}

.skeleton-profile-img {
	height: 18rem;
	width: 18rem;
	border-radius: 50%;
}

.skeleton-profile-name {
	height: 3.5rem;
	width: 35rem;
	margin-bottom: 3.2rem;
}

.skeleton-profile-item {
	height: 2.5rem;
	width: 10rem;
}