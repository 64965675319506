@use '../4-helpers' as *;

.c-home {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	justify-content: center;
	border-radius: .4rem;
	padding: 2rem;
	@include themed() {
		border: .1rem solid t('primary-border');
		background-color: t('primary-bg');
	}
}

.home-content {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.home-content-textblock {
	display: flex;
	flex-direction: column;
	margin-left: 1.6rem;
}

.home-icon-wrapper {
	width: 10rem;
	height: 10rem;
	@include themed() {
		background-color: t('icon-home-bg');
	}
}