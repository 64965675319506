@use '../4-helpers' as *;

.c-error-page {
	display: flex;
	height: 100vh;
	align-items: center;
	justify-content: center;
	background: radial-gradient(circle at 1% -22%,rgba($color-secondary-base, 0.1), rgba($color-secondary-base, 0.1) 10%, transparent 10%, transparent 20%, rgba($color-secondary-base, 0.1) 20%, rgba($color-secondary-base, 0.1) 30%, transparent 30%),
							radial-gradient(circle at 105% 110%,rgba($color-secondary-base, 0.1), rgba($color-secondary-base, 0.1) 10%, transparent 10%, transparent 20%, rgba($color-secondary-base, 0.1) 20%, rgba($color-secondary-base, 0.1) 30%, transparent 30%);
}

.error-page {
	&__text-block {
		padding-top: 1.2rem;
		padding-bottom: 2.4rem;
	}

	&__icon-wrapper {
		width: 12rem;
		height: 12rem;
		background-color: $color-secondary-light;
	}
}

.error-page-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 47.8rem;
}

.error-page-title {
	font-size: 3.6rem;
	line-height: 4.3rem;
	text-align: center;
}

.error-page-text {
	margin-top: 1.2rem;
	line-height: 2.6rem;
	text-align: center;
}

