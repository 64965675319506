@use "../4-helpers" as *;

.c-main-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 0.4rem;
  padding: 2rem;

  @include themed() {
    border: 0.1rem solid t("primary-border");
    background-color: t("primary-bg");
    padding: 3.8rem;
  }

  &--home {
    flex-grow: 1;
    align-items: center;
    justify-content: center;
  }

  &--form {
    flex-grow: 1;
    padding: 0;
  }

  &--table {
    padding: 0;
    header {
      padding: 0;
      width: 100%;
      .MuiPaper-elevation1 {
        box-shadow: none;
        border-radius: 0;
      }

      .MuiAccordionSummary-content.Mui-expanded {
        margin: 0;
      }

      .MuiAccordionSummary-content {
        margin: 0;
      }
    }
    //fixed classes from the react-data-table-component//
    .rdt_TableCol_Sortable {
      span.__rdt_custom_sort_icon__ svg {
        width: 1.2rem;
        height: 1.2rem;
      }
    }

    .rdt_Table {
      @include themed() {
        background-color: t("tb-bg");
      }
    }

    .rdt_TableHeadRow {
      height: 6.5rem;
      border: none;
      border-radius: 0;
      @include themed() {
        background-color: t("tb-header-bg");
        color: t("tb-text");
      }
    }

    .rdt_TableRow {
      height: 6.5rem;
      &:hover {
        @include themed() {
          background-color: t("tb-row-hover-bg");
          color: t("tb-row-hover-text");
        }
      }
      /*&:not(:last-of-type) {
				@include themed() {
					border-bottom: .1rem solid t('tb-item-border');
					background-color: t('tb-bg');
					color: t('tb-text');
				}
			}*/
    }

    .rdt_Pagination {
      padding-right: 3rem;
      @include themed() {
        background-color: t("tb-bg");
        border-top: 0.1rem solid t("tb-item-border");
        color: t("tb-text");
      }

      #pagination-first-page,
      #pagination-next-page,
      #pagination-previous-page,
      #pagination-last-page {
        display: flex;
        align-items: center;
        justify-content: center;
        @include themed() {
          background-color: t("btn-tb-pagination-bg");
          color: t("btn-tb-pagination-icon");
        }
        margin: 0 0 0 0.6rem;
        height: 3.6rem;
        width: 3.6rem;

        &:hover:not(:disabled) {
          @include themed() {
            color: t("btn-tb-pagination-icon-hover");
            background-color: t("btn-tb-pagination-bg-hover");
          }
        }

        &:disabled {
          @include themed() {
            border: 0.1rem solid t("btn-tb-pagination-border");
            background-color: t("btn-tb-pagination-bg-disabled");
            color: t("btn-tb-pagination-icon-disabled");
          }
        }
      }
    }
  }
}

.container {
  background-color: #f5f5f5; 
  padding: 2rem; 
  border-radius: 8px; 
  flex: 1; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 0;
}

.internal-pages {
  background-color: #fff;
  margin-top: 6.5rem;
  margin-left: 20rem;
  border-radius: 4px
}