@use '../4-helpers' as *;

.icon {
	&--password {
		color: $color-primary-base;
	}

	&--close {
		@include themed() {
			color: t('btn-modal-icon');
		}	
	}

	&--checkbox {
		width: 2.4rem;
		height: 2.4rem;
	}

	&--warning {
		width: 1.6rem;
		height: 1.6rem;
		margin-right: .8rem;
		color: $color-warning-base;
	}

	&--warning-big {
		width: 6.5rem;
		height: 6rem;
		color: $color-primary-base;
	}

	&--sidebar {
		min-width: 1.2rem;
		min-height: 1.2rem;
		@include themed() {
			color: t('sidebar-icon');
		}
	}

	&--btn-sidebar {
		height: 4rem;
		width: 4rem;
		color: $color-neutral-darker;
	}

	&--tooltip {
		width: 1.6rem;
		height: 1.6rem;
		color: $color-helper-base;
	}

	&--open-menu {
		width: 1.4rem;
		height: 1.4rem;
		@include themed() {
			color: t('btn-user-profile-icon-active')
		}
		margin-left: 1.2rem;
	}

	&--closed-menu {
		width: 1.4rem;
		height: 1.4rem;
		@include themed() {
			color: t('btn-user-profile-icon');
		}
		margin-left: 1.2rem;
	}

	&--arrow-sidebar-menu {
		width: 1.1rem;
		height: 1.1rem;
		color: $color-neutral-lightest;
	}

	&--arrow-select {
		position: absolute;
		right: 1.7rem; 
		top: calc(50% - .5rem); 
		pointer-events: none;
		width: 1.1rem;
		height: 1.1rem;
		color: $color-primary-base;
	}

	&--arrow-select-form {
		@include themed() {
			color: t('icon-select');
		}
	}

	&--arrow-left-btn {
		margin-right: 1.4rem;
		width: 1.2rem;
		height: 1.2rem;
	}

	&--arrow-right-btn {
		margin-left: 1.4rem;
		width: 1.2rem;
		height: 1.2rem;
	}

	&--home {
		width: 4rem;
		height: 4rem;
		@include themed() {
			color: t('icon-home-fill');
		}
	}

	&--table {
		width: 6rem;
		height: 6rem;
		@include themed() {
			color: t('icon-table-fill');
		}
	}

	&--table-avatar {
		@include themed() {
			color: t('icon-table-avatar-stroke');
			background-color: t('icon-table-avatar-bg');
		}
	}

	&--table-checkbox {
		@include themed() {
			color: t('icon-table-checkbox');
			fill: t('icon-table-checkbox-bg');
		}
	}

	&--table-checkbox-checked {
		@include themed() {
			color: t('icon-table-checkbox-checked');
		}
	}

	&--table-sort {
		margin-left: 1.1rem;
	}

	&--search {
		z-index: 2;
		margin-right: -2.8rem;
		@include themed() {
			color: t('icon-search');
		}
	}

	&--loading {
		width: 2.4rem;
		height: 2.4rem;
		color: $color-primary-base;
		border-radius: .4rem;
	}

	&--menu-item {
		width: 2rem;
		height: 2rem;
		margin-right: 1rem;
	}

	&--user-profile-item {
		width: 1.6rem;
		height: 1.6rem;
		color: $color-primary-base;
	}

	&--linear-stepper-uncompleted-item {
		width: 2rem;
		height: 2rem;
		color: $color-primary-base;
	}

	&--linear-stepper-completed-item {
		width: 2rem;
		height: 2rem;
		color: $white;
	}

	&--user-profile-avatar {
		width: 4rem;
		height: 4rem;
		color: $color-primary-base;
	}

	&--accordion-chevron {
		width: 2rem;
		height: 2rem;
		@include themed() {
			color: t('icon-select');
		}
	}

	&--multi-form-chevron {
		color: $color-primary-base;
	}

	&--toast-warning {
		width: 2rem;
		height: 2rem;
		@include themed() {
			color: t('icon-toast-error');
		}
	}

	&--radio-button {
		width: 4.2rem;
		height: 4.2rem;
	}

	&--no-result {
		width: 4rem;
		height: 4rem;
		@include themed() {
			color: t('icon-table-fill');
		}
	}

	&--edit-btn {
		width: 1.4rem;
		height: 1.4rem;
		color: $color-neutral-darkest;
		margin-right: .8rem;
	}

	&--tenant-selector {
		color: $color-neutral-darkest;
	}

	&--Search-btn{
		margin-left: 1rem;
	}
}