@use "../4-helpers" as *;

.c-dashboard {
  display: flex;
  min-height: 100vh;
}

.c-dashboard-main-content {
  display: flex;
  flex-direction: column;
  margin: 8rem auto 0 auto;
  padding: 2rem 2rem 0 2rem;
  width: 100%;
  overflow: hidden;

  .title,
  .alert_type_name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 250px;
  }

  @include responsiveMinWidth(1988) {
    .date-input {
      position: relative;
      font-size: 1.4rem;
      width: 25.5rem;
    }
    
    .date-input::placeholder {
      font-size: 1.4rem;
    }
    .title {
      width: 100%;
    }
    .alert_type_name {
      width: 250px;
    }
    .arrow-date {
      width: auto;
      position: absolute;
      top: -7px;
    }
    .close-button {
      margin-top: 10px;
    }
    .date_start_rr,
    .date_end_rr {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 1.4rem;
    }
  }

  @include responsiveMaxWidth(2626) {
      .date-input {
        position: relative;
        font-size: 1.6rem;
        width: 25.5rem;
      }
      
      .date-input::placeholder {
        font-size: 1.6rem;
      }

    .title,
    .alert_type_name {
      width: 259px;
    }
  }

  @include responsiveMaxWidth(1920) {
    .date-input {
      position: relative;
      font-size: 1.4rem;
      width: 25.5rem;
    }
    
    .date-input::placeholder {
      font-size: 1.4rem;
    }

    .datetime,
    .date_start,
    .date_end,
    .risk_level {
      font-size: 1.4rem;
      width: 100px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .title,
    .alert_type_name {
      width: 180px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 1.4rem;
    }

    .locale,
    .occurrence,
    .date_start_rr,
    .date_end_rr {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 1.4rem;
    }

    .close-button {
      margin-top: 7px;
    }
  }

  @include responsiveMaxWidth(1811) {
    .date-input {
      position: relative;
      font-size: 1.4rem;
      width: 25rem;
    }
    
    .date-input::placeholder {
      font-size: 1.4rem;
    }

    .arrow-date {
      width: auto;
      position: absolute;
      top: -7px;
    }

    .hide-columns-menu {
      width: 25rem;
      height: 4rem;
    }

    .new-table-item-btn {
      width: 25rem;
      height: 4rem;
    }

    .search-table-btn {
      width: 15rem;
      height: 4rem;
    }
    .btn-icon-search {
      width: 15px;
    }
  }

  @include responsiveMaxWidth(1650) {
    .date-input {
      width: 23rem;
      height: 4rem;
      position: relative;
      font-size: 1.3rem;
    }

    .date-input::placeholder {
      font-size: 1.3rem;
    }

    .arrow-date {
      position: absolute;
      left: 30px;
      top: -5px;
    }

    .hide-columns-menu {
      width: 18.5rem;
      font-size: 13px;
      height: 4rem;
    }

    .search-table-btn {
      width: 13rem;
      height: 4rem;
      font-size: 13px;
    }

    
    .new-table-item-btn {
      width: 20rem;
      height: 4rem;
      font-size: 13px;
    }
  }

  @include responsiveMaxWidth(1600) {
    .date-input {
      position: relative;
      font-size: 1.3rem;
      width: 20rem;
    }

    
    .date-input::placeholder {
      font-size: 1.3rem;
    }

    .new-table-item-btn {
      width: 25rem;
      height: 4rem;
    }

    .datetime,
    .date_start,
    .date_end,
    .risk_level {
      width: 100px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 1.3rem;
    }

    .locale,
    .occurrence,
    .date_start_rr,
    .date_end_rr {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 1.3rem;
    }

    .title,
    .alert_type_name {
      width: 40px;
      font-size: 1.3rem;
    }
  }

  @include responsiveMaxWidth(1400) {

    .date-input {
      position: relative;
      font-size: 1.2rem;
      width: 18rem;
    }

    
    .date-input::placeholder {
      font-size: 1.2rem;
    }

    .titleSeparator {
      font-size: 16px;
    }

    .search-table-btn {
      width: 12rem;
      height: 4rem;
      font-size: 12px;
    }

    .new-table-item-btn {
      width: 17rem;
      height: 4rem;
      font-size: 12px;
    }

    .hide-columns-menu {
      font-size: 12px;
      width: 20rem;
      height: 4rem;
    }

    .btn-icon-search {
      width: 14px;
      font-size: 12px;
    }

    .date-input {
      width: 18rem;
      position: relative;
    }

    .date-input::placeholder {
      font-size: 12px;
    }
  }

  @include themed() {
    background-color: t("secondary-bg");
  }

  &--open {
    margin-left: 23rem;
    padding-left: 2rem;
    transition: margin-left 0.3s;
  }

  &--closed {
    margin-left: 8rem;
    transition: margin-left 0.3s;
  }
}
