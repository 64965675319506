.form-container {
  padding: 40px;
  width: 480px;
}

.form-container h1 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: start;
}

.form-container p {
  font-size: 16px;
  color: #666;
  margin-bottom: 30px;
}

.local-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.form-group label {
  font-size: 14px;
  margin-bottom: 5px;
}

.form-group input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.button-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.primary-btn {
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background-color: #4120CE;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.primary-btn:hover {
  background-color: #4d2fb3;
}

.secondary-btn {
  padding: 10px;
  font-size: 16px;
  color: #623ce4;
  font-weight: 600;
  background-color: #fff;
  border: 2px solid #623ce4;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
}

.secondary-btn:hover {
  background-color: #f3f0fc;
}