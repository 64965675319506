@use '../4-helpers/' as *;

.toast {
	border-radius: .4rem;
	height: 8rem;
	width: 60rem;
	@include themed() {
		background-color: t('primary-bg');
		border: .1rem solid t('primary-border');
		color: t('primary-text');
	}
}

.c-toast-message {
	display: flex;
	align-items: center;
}

.c-toast-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 3.6rem;
	height: 3.6rem;
	margin-right: 1.2rem;
	@include themed() {
		background-color: t('icon-toast-error-bg');
	}
}