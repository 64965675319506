.plan-modal-icon {
  border-radius: 50%;
  margin: 0 auto 16px 0;
}

.plan-modal-title {
  font-size: 24px;
  margin: 10px 0 25px;
  color: #333;
  font-weight: bold;
  text-align: left;
}

.plan-modal-description {
  font-size: 14px;
  color: #292929;
  margin: 0 0 30px;
  text-align: left;
}