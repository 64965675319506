.edit-user-btn {
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background-color: #4120CE;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  margin-top: 20px;
}