.c-login-page {
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	height: 100vh;

	@media (max-width: 768px) {
		flex-direction: column;
	}
}

.banner-login {
	width: 50vw !important;
	height: 100vh !important;

	@media (max-width: 768px) {
		width: 100vw !important;
	}
}

.c-right {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
}

.c-right-login {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
}

.c-right-login img {
	max-width: 30%;
}

.c-left {
	width: 50vw;
	height: 100vh;
	position: relative;
	background-image: url('../../assets/img/grafismos.png'), url('../../assets/img/cittua_login_bg.png');
	background-repeat: no-repeat, no-repeat;
	background-size: cover, cover;
	background-position: -25rem 0, -10rem 0;
}

.c-login-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-height: 50% !important;
	max-width: 38%;
	box-sizing: border-box;
	margin: auto;

	@media (max-width: 768px) {
		max-height: 100%;
		max-width: 90%;
		margin-top: 2rem;
	}
}

.c-recaptcha {
	display: flex;
	flex-direction: column;
	align-items: left;
	justify-content: center;
	height: 10rem;
	margin: 1rem 0;
}

.logo-img {
	width: 20rem;
	height: 5.5rem;
}

.c-tenant-selection {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
}

.selection-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 43.9rem;
}

.tenant-selection-title {
	margin-top: 1rem;
	text-align: center;
}

.login-form__title {
	text-align: left;
	padding: 3rem 20rem 0 4rem;
	font-weight: 700;
	font-size: 3.5rem;
	line-height: 3.5rem;

	@media (max-width: 1366px) {
		font-size: 3rem;
		padding: 3rem 10rem 0 0;
	}
}


// General

.card-internal {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: left;
	max-width: 25%;
	box-sizing: border-box;
	margin: auto;

	@media (max-width: 768px) {
		max-width: 90% !important;
		justify-content: center !important;

	}

	@media (max-width: 1980px) {
		max-width: 35%;
		padding-bottom: 10rem;
	}
}

.form-title {
	text-align: left;
	padding: 0 0 3rem 0 !important;
	font-weight: 700;
	font-size: 2.7rem;
	line-height: 4.2rem !important;

	@media (min-width: 1400px) {
		font-size: 3.5rem;
		line-height: 3.5rem;
		padding: 3.6rem 0 1rem 0;
	}
}

.internal-form {
	margin-top: 1rem !important;

	@media (max-width: 768px) {
		max-width: 90% !important;
		justify-content: center;
	}
}

.form-text-type1 {
	color: #292929;
	font-size: 1.5rem;
	line-height: 2.5rem;
	font-weight: 500;
}

.form-text-type2 {
	color: #4120CE;
	font-size: 1.5rem;
	line-height: 2.5rem;
	font-weight: 700;
	max-width: 80%;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.phone-input-group {
	display: flex;
	align-items: center;
	gap: 10px;

	@media (max-width: 768px) {
		flex-direction: column;
	}
}

.country-flag {
	width: 20px;
	height: 15px;
	margin-right: 10px;
}

.select-option {
	display: flex;
	align-items: center;
	padding: 5px;
}

.select-single-value {
	display: flex;
	align-items: center;
	width: 80px;
}

.select-single-value span {
	font-size: 1.5rem;
}

.terms-internal-pages {
	margin-top: 3rem !important;
	margin-bottom: 0.8rem !important;
}

// O react-select tem estilização padrão, estas abaixo anulam algumas delas para manter o padrão da aplicação
.react-select__input-container {
	display: none;
}

.react-select__indicator-separator {
	display: none;
}

.react-select__dropdown-indicator {
	padding: 4px;
}

.react-select__control {
	border: 0.1rem solid #E0E0E0;
	transition: none
}

.react-select__control:hover {
	border: 0.1rem solid #E0E0E0;
}