@use '../4-helpers' as *;

.c-details-content {
	padding: 4.8rem;
	width: 60%;
}

.c-details-item {
  padding-bottom: 3.2rem;
}

p.details-label {
  color: $color-primary-base;
  margin-bottom: 1.2rem;
}

.group-details {
  padding: 3.2rem 0 0 0;
}

.c-nav-btn {
  padding-top: 1.6rem;  
}

.skeleton-details-label {
  height: 2rem;
	width: 10%;
	margin-bottom: 1rem;
	border-radius: .4rem;
}

.skeleton-details-value {
  width: 50%;
  height: 4rem;
	border-radius: .4rem;
}

.c-details-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}