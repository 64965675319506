General reset * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden
}

body {
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #f9f9f9;
}

.app-container {
  display: flex;
  min-height: 100vh;
}

/* Sidebar styles */
.sidebar {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.logo-container {
  border-bottom: 1px solid #E9ECEF;
}

.logo {
  padding: 20px 100px 10px 30px;
  border-bottom-width: 1px;
}

.app-container>aside>nav {
  padding: 10px 10px 0 8px;
}

.app-container nav ul {
  list-style: none;
  padding: 15px 0 0 0;
}

.app-container nav ul li {
  font-size: 18px;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 12px 24px;
  text-decoration: none;
}

.app-container nav ul li a {
  display: flex;
  gap: 10px;
  align-items: center;
  text-decoration: none;
  color: #8A92A6;
}

.app-container .active {
  background-color: #D5CDF4;
  border-bottom-right-radius: 32px;
  border-top-right-radius: 32px;
}

.app-container .active a {
  color: #623ce4;
}

.app-container .active a img {
  filter: invert(23%) sepia(87%) saturate(3564%) hue-rotate(248deg) brightness(91%) contrast(97%);
}

.app-container nav ul li:hover {
  background-color: #D5CDF4;
  border-bottom-right-radius: 100px;
  border-top-right-radius: 100px;
}

.app-container nav ul li:hover:not(:last-child) a {
  color: #623ce4;
  background-color: #D5CDF4;
  border-bottom-right-radius: 100px;
  border-top-right-radius: 100px;
}


.app-container nav ul li:hover:not(:last-child) img {
  filter: invert(23%) sepia(87%) saturate(3564%) hue-rotate(248deg) brightness(91%) contrast(97%);
}

.app-container ul li:last-child a {
  color: #DB1A17
}

.app-container ul li img {
  width: 20px;
  height: 20px;
  fill: #623ce4;
}

.app-container nav ul li:hover:last-child {
  background-color: #e7a4a4;
  border-bottom-right-radius: 100px;
  border-top-right-radius: 100px;
}

.sidebar-breakpoint {
  border: 1px solid #E9ECEF;
  margin: 0 0 10px 0;
}

/* Main content styles */
.main-content {
  flex: 1;
  background-color: #F5F5F5;
}

.header {
  background-color: #FFFFFF;
  padding: 20px 10.5px 1px 25px;
  border-bottom: 1px solid #E9ECEF;
}

header h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.content-container {
  display: flex;
  justify-content: center;
  background-color: #FFFFFF;
  margin: 10px;
  border-radius: 4px;
}

.content {
  padding: 40px;
}

.info-locations-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  width: 400px;
}

.intro h2 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 10px;
}

.intro p {
  font-size: 13px;
  line-height: 25.6px;
  margin-bottom: 15px;
  font-weight: 400;
}

.userLocal-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  margin-bottom: 30px;
}

.userLocal-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.userLocal-buttons button,
.userLocal-buttons a {
  padding: 7px 10px;
  border: none;
  border-radius: 5px;
  background-color: #EDEAFA;
  color: #4120CE;
  cursor: pointer;
  text-decoration: none;
}

.add-location-btn {
  display: inline-block;
  padding: 20px 20px;
  width: max-content;
  font-size: 16px;
  color: #5A5463;
  border: 2px dashed #CAC8D1;
  background: none;
  border-radius: 10px;
  margin-bottom: 40px;
  cursor: pointer;
  width: 100%;
}

.card {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.1);
  width: 330px;
  height: 339px;
  align-self: center;
}

.card h3 {
  font-size: 18px;
  color: #623ce4;
  margin-bottom: 10px;
}

.card p {
  font-size: 14px;
  margin-bottom: 20px;
}

.image-placeholder {
  display: flex;
  justify-content: center;
  width: 100%;
}

.image-placeholder>svg {
  height: 200px;
  width: 200px;
}