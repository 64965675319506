@use "../4-helpers" as *;

.c-header {
  position: fixed;
  z-index: 1180;
  display: flex;
  height: 8rem;
  align-items: center;
  width: 100%;
  @include themed() {
    background-color: t("primary-bg");
    border-bottom: 0.1rem solid t("primary-border");
  }
  &--open {
    padding: 1.9rem 2.5rem 1.9rem 27.2rem;
    transition: padding 0.3s;
  }

  &--closed {
    padding: 1.9rem 2.5rem 1.9rem 11.3rem;
    transition: padding 0.3s;
  }
}

/*====================================================================*/
/*  Header Components
/*====================================================================*/

.c-right-component {
  margin-left: auto;
}

.c-avatar-img {
  width: 3.6rem;
  height: 3.6rem;
  @include themed() {
    background-color: t("btn-user-profile-avatar-bg");
    color: t("btn-user-profile-avatar-fill");
  }
}

.avatar-border {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.2rem;
  height: 4.2rem;
  border-radius: 50%;
  @include themed() {
    border: 0.2rem solid t("btn-user-profile-avatar-border");
  }

  &--open-menu {
    @include themed() {
      border: 0.2rem solid t("btn-user-profile-icon-active");
    }
  }
}

.table-check {
  position: relative;
  right: 1rem;
}

.avatar-img {
  width: 3.6rem;
  height: 3.6rem;
}

.skeleton-avatar-img {
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 50%;
}

.menu {
  border-radius: 0.4rem;

  border: 0.1rem solid $color-neutral-light;
  @include themed() {
    background-color: t("primary-bg");
    color: t("primary-text");
    border: 0.1rem solid t("primary-border");
    box-shadow: 0.4rem 0.4rem 0.8rem rgba(t("box-shadow"), 0.1);
  }

  &--form {
    margin-top: 0.4rem;
  }

  .MuiList-padding {
    padding: 0;
  }
}

.lines-ellipsis {
  max-width: 15rem;
  white-space: pre-wrap;
  word-break: break-all;
}

.menu-item {
  height: 4.6rem;
  padding: 1.2rem 1.6rem;

  &:hover {
    @include themed() {
      background-color: t("menu-hover");
    }
  }

  &--hidden {
    display: none;
  }

  &.Mui-selected,
  &.Mui-selected:hover {
    @include themed() {
      background-color: t("menu-selected");
    }
  }
}

.switch {
  display: flex;
  width: 5.2rem;
  height: 2.4rem;
  padding: 0;
  align-items: center;
  justify-content: center;

  &--margin-left {
    margin-left: 1.4rem;
  }

  .MuiSwitch-track {
    width: 100%;
    height: 100%;
    border-radius: 3.5rem;
    @include themed() {
      background-color: t("switch-track-bg");
    }

    border: 0.1rem solid $color-primary-base;
  }

  .MuiSwitch-thumb {
    width: 2rem;
    height: 2rem;
  }

  .MuiSwitch-switchBase {
    top: auto;
    padding: 0.3rem;
    @include themed() {
      color: t("switch-base");
    }
  }

  .MuiSwitch-switchBase.Mui-checked {
    transform: translateX(2.7rem);
    @include themed() {
      color: t("switch-base-checked");
    }
  }

  .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    @include themed() {
      background-color: t("switch-track-bg");
    }

    opacity: 1;
    border: none;
  }
}

.c-page-title {
  display: flex;
  align-items: center;
}

.page-title-text {
  margin-left: 1cap;
  font-size: 3.2rem;
}

.simple-header {
  margin-left: 20rem;
  background-color: #fff;
  height: 7.3rem;
  border-bottom: 0.1rem solid #ddd;
}